import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"


const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap; 
`

const StyledScales = styled.section`
    padding: 80px 10px;
	max-width: 1100px;
	margin: 0 auto;
    
	  ${breakpoint.small`
		flex-wrap: nowrap;
		padding: 120px 20px;
	  `}

  .block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
 	column-gap: 40px;

    ${breakpoint.small`
      flex-wrap: nowrap;
    `}

    &--image {
      	width: 100%;
      	margin: 0 auto;
		display: flex;
		justify-content: center;

		${breakpoint.small`
			display: block;
			width: 80%;
      		max-width: 550px;
		`}

		.imageResize {
			max-width: 650px;

		}

	 }

    &--content {
		width: 100%;
	
		${breakpoint.small`
			width: 60%;
			margin-top: 40px;
		`}

		h2 {
			margin-bottom: 40px;
			text-align: center;
			
			${breakpoint.small`
				text-align: left;
			`}
		}

		p {

			&:first-of-type {
			padding-bottom: 2rem;
			}
		}
	  
	}
  } 
`

const Scales = () => {
	return (
		<StyledScales>
			<StyledContainer>
				<div className="block">
					<div className="block--content">
						<h2 data-aos="fade-up" data-aos-once="false">Scale <br />as surges<br />happen</h2>
						<p data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
						The instrument free Visby Medical Respiratory Health Test allows testing for COVID-19 and flu where it’s most effective, at the point of care.
						</p>
					</div>

					<div className="block--image" data-aos="fade-up" data-aos-once="false">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/sneeze.jpg"
							width={800}
							alt="Visby Medical Respiratory Health Test"
							className="imageResize"
						/>
					</div>
				</div>
			</StyledContainer>
		</StyledScales>
	)
}

export default Scales
